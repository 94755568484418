@import "./assets/scss/modal.scss";
@import "~swiper/swiper.scss";

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

body {
  overflow-x: auto;
  /* Hide scrollbars */
  overflow-wrap: break-word;
  // word-break: break-all;
  overflow-y: hidden;
}

.app-desc {
  color: #868383;
}
.scroll {
  overflow: auto !important;
}
.ui.button {
  border-radius: 8px;
}

.ui.secondary.segment {
  background: #fcfcfc;
}

#no-radius {
  border-radius: 0px;
  background: #86879d33;
}

#rounded {
  border-radius: 0px;
}

.detail-panel:hover {
  background: #064e3b !important;
  color: #fff;

  .sub-header,
  .sub-item {
    color: #fff;
  }
}

.detail-panel {
  border-radius: 8px;
  padding: 15px;
  background: #f4f4f4 !important;
  // .sub-header {
  // 	color: #86879d;
  // }
}

.chat-panel:hover {
  background: #f4f4f4 !important;

  // .sub-header,
  .sub-item {
    color: #fff;

    #content {
      background: #fff;
      color: #505050;
    }
  }
}

.chat-panel {
  border-radius: 8px;
  padding: 15px;
}

.ui.circular.button {
  padding: 0.7em;
  border-radius: 5677rem;
}

.banner-panel {
  background: #f0f1f8 !important;
  border-radius: 4px;
  // padding: 8px;
}

.side-panel {
  background: #e3e4ec !important;
  border-radius: 8px;
  padding: 8px;
}

.ui.circular.button > .icon:not(.button) {
  margin: 0 !important;
}

.link {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

#dark {
  background: #064e3b;
  color: #fff;
}

#tertiary {
  color: #979797 !important;
}

#light {
  background: rgba(234, 239, 251, 0.5);
  border-radius: 8px;
  background: #eaeffb;
  color: #064e3b;
}

.ui.tertiary.segment {
  border: none;
  color: #505050;
  background: linear-gradient(
      0deg,
      rgba(234, 239, 251, 0.5),
      rgba(234, 239, 251, 0.5)
    ),
    #fbfaea;
}

.ui.modal {
  position: absolute;
  display: none;
  z-index: 1001;
  text-align: left;
  background: #ffffff;
  border: none;
  box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.2),
    1px 3px 15px 2px rgba(0, 0, 0, 0.2);
  transform-origin: 50% 25%;
  flex: 0 0 auto;
  border-radius: 0.28571429rem;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  will-change: top, left, margin, transform, opacity;
}

.ui.button {
  background: #f0f1f8;
}

.ui.link.button {
  color: #064e3b;
  background: #f0f1f8;
}

.sub-header {
  font-family: RobotoLight, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #505050;
}

.sub-item {
  font-family: RobotoLight, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: #86879d;
}

.sub-value {
  color: #505050;
  font-size: 16px;
  line-height: 24px;
}

.sub-label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  color: #979797;
}

.description {
  color: #979797;
}

.ui.primary.header {
  margin-top: 0;
}

.ui.circular.label {
  background: #86879d;
  color: #fff;
}

.selected-row {
  background: #f4f4f4;
  padding: 8px;
}

.ui.white.button {
  font-weight: normal;
  background-color: #fff;
  color: #505050;
  background-image: none;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
}

.ui.white.button:hover {
  background-color: #064e3b;
  color: #fff;
}

.ui.primary.button,
.ui.primary.buttons .button {
  background-color: #064e3b;
  color: #fff;

  text-shadow: none;
  background-image: none;
}

.ui.primary.button {
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
}

.ui.primary.button:hover,
.ui.primary.buttons .button:hover {
  background-color: #064e3b;
  color: #fff;
  text-shadow: none;
}

.ui.primary.button:focus,
.ui.primary.buttons .button:focus {
  background-color: #064e3b;
  color: #fff;
  text-shadow: none;
}

.ui.primary.button:active,
.ui.primary.buttons .button:active {
  background-color: #064e3b;
  color: #fff;
  text-shadow: none;
}

.ui.primary.active.button,
.ui.primary.button .active.button:active,
.ui.primary.buttons .active.button,
.ui.primary.buttons .active.button:active {
  background-color: #064e3b;
  color: #fff;
  text-shadow: none;
}

// .swiper-button-prev {
// 	width: 0;
// 	height: 0;
// 	border-top: 10px solid transparent;
// 	border-bottom: 10px solid transparent;

// 	border-left: 10px solid #007aff;
// }

// .swiper-button-next {
// 	width: 0;
// 	height: 0;
// 	border-top: 10px solid transparent;
// 	border-bottom: 10px solid transparent;

// 	border-right: 10px solid #007aff;
// }

@import "~swiper/components/pagination/pagination.scss";
@import "~swiper/components/navigation/navigation.scss";

// .swiper {
// 	width: 100%;
// 	height: 100%;
// }
// .swiper-wrapper {
//   // margin-left: 45px;
// }

.swiper-slide {
  background: #e9ebf357;
  border-radius: 5px;
  padding: 10px;
  height: 100px;
  width: 230px;
}

// .swiper-slide img {
// 	display: block;
// 	width: 100%;
// 	height: 100%;
// 	object-fit: cover;
// }

.swiper {
  width: 100%;
  height: 400px;
  margin: 20px auto;
}

.append-buttons {
  text-align: center;
  margin-top: 20px;
}

.append-buttons button {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #007aff;
  color: #007aff;
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 13px;
}

// .swiper-slide {
// 	background: #f0f1f8;
// 	border-radius: 5px;
// 	padding: 5px;
// 	height: 120px;
// }
// .swiper-button-prev {
// 	width: 0;
// 	height: 0;
// 	border-top: 10px solid transparent;
// 	border-bottom: 10px solid transparent;

// 	border-left: 10px solid #007aff;
// }

// .swiper-button-prev {
// 	width: 0;
// 	height: 0;
// 	border-top: 10px solid transparent;
// 	border-bottom: 10px solid transparent;

// 	border-right: 10px solid #007aff;
// }

// .scroll-wrapper {
//   overflow: auto;
// }
